<template>
  <section id="hero">
    <!-- class="skew" -->
    <v-img
      style="background-color: #234e79; background-image: linear-gradient(110deg, #234e79 0%, #9895e2 100%);"
    >
      <!-- class="deskew" -->
      <v-container
        class="fill-height px-4"
        :class="$vuetify.breakpoint.mdAndDown ? 'pt-16 pb-16' : ''"
      >
        <v-row
          :class="$vuetify.breakpoint.mdAndDown ? 'mt-16' : ''"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-responsive
              class="d-flex align-center"
              height="100%"
              max-width="700"
              width="100%"
            >
              <!-- <base-heading
                title="Automate your paperwork"
                :size="'display-3'"
                :weight="'thin'"
              /> -->
              <p
                :style="calculateStyle($vuetify.breakpoint.width)"
                class="w3-animate-left"
              >
                Automate your paperwork
              </p>

              <base-body
                font-color="secondary--text"
                class=" w3-animate-right"
              >
                <span style="font-size: 22px; line-height: 1.5;">The smart digital assistant for effective and time saving processing of documents, templates and cases.</span>
              </base-body>

              <div
                :class="$vuetify.breakpoint.smAndDown ? 'align-start' : 'align-center'"
                class="d-flex flex-wrap w3-animate-bottom"
              >
                <v-btn
                  min-height="35"
                  min-width="150"
                  rounded
                  small
                  href="https://app.lavvira.net/register"
                  outlined
                >
                  Start free trial now
                </v-btn>

                <v-btn
                  min-height="35"
                  min-width="150"
                  text
                  small
                  class="ml-2"
                  color="white"
                  @click="goToPricing"
                >
                  choose plan
                  <v-icon
                    small
                    rounded
                  >
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </div>
            </v-responsive>
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="6"
          >
            <v-img
              :min-height="minHeight"
              :min-width="minWidth"
              :src="require('@/assets/lavvira-hero.png')"
              class="w3-animate-zoom"
            />
            <!-- style="transform: scale(-1, 1);" -->
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '60vh' : '30vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      minWidth () {
        const width = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${width} - ${this.$vuetify.application.top}px)`
      },
    },
    mounted () {
      // const style = document.createElement('link')
      // style.type = 'text/css'
      // style.rel = 'stylesheet'
      // style.href = 'https://www.w3schools.com/w3css/4/w3.css'
      // document.head.appendChild(style)
    },
    methods: {
      goToPricing () {
        const element = document.getElementById('pricing')
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      },
      calculateStyle (input) {
        if (input >= 410) return 'font-size: 80px; font-weight: 600; line-height: normal; color: white'
        else if (input < 410 && input >= 370) return 'font-size: 70px; font-weight: 600; line-height: normal; color: white'
        else if (input < 370 && input >= 330) return 'font-size: 60px; font-weight: 600; line-height: normal; color: white'
        else if (input < 330 && input >= 270) return 'font-size: 50px; font-weight: 600; line-height: normal; color: white'
        else if (input < 270) return 'font-size: 30px; font-weight: 600; line-height: normal; color: white'
      },
    },
  }
</script>

<style>
.skew {
   transform: skewY(-12deg);
   transform-origin: 0;
}
.deskew {
   transform: skewY(12deg);
   transform-origin: 0;
}
</style>
